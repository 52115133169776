const { mapState, mapMutations } = window.Vuex;

export default (module) => ({
  data () {
    return {
      id: '',
      curItem: {},
      utils: window.utils,
      LABEL_WIDTH: 100,
      status: '',
      loading: false
    }
  },
  computed: {
    ...mapState({
      restfulApi: state => state[module].restfulApi,
      findItemApi: state => state[module].findItemApi,
    })
  },
  methods: {
    ...mapMutations(module, ['payload']),
    // 新增/修改单条
    saveItem (data = {}) {
      this.loading = true;
      const method = this.status == 'add' ? 'post' : 'put';
      const api = this.status == 'add' ? this.addApi : this.saveApi;
      const message = this.status == 'add' ? '新增' : '修改';
      let params = { ...data };
      // 获取保存参数
      if (this.getSaveParams) {
        params = this.getSaveParams();
      }
      // 保存拦截器
      // 非必须
      // 返回 true false
      if (this.beforeSaveHandler) {
        let isSave = this.beforeSaveHandler(params);
        if (!utils.is_boolean(isSave)) {
          throw 'beforeSaveHandler 作为一个更新前置操作，必须返回 true 或 false;'
        }
        if (isSave == false) {
          return false;
        }
      }
      if (Object.keys(params).length) {
        this
          .$request[method](`${api || this.restfulApi}`, params)
          .then(res => {
            this.loading = false;
            this.$Message.info(`${message}成功!`);
            this.payload({
              type: 'nUdid',
              value: window.utils.uuid()
            });
            if (this.submitSuccess) {
              this.submitSuccess();
            }
          })
          .catch(() => {
            this.loading = false;
          })
      } else {
        this.loading = false;
        throw '请求参数不完整';
      }
    },
    // 查询单条记录
    findItem (id) {
      this.loading = true;
      return this
        .$request
        .get(`${this.findItemApi || this.restfulApi}/${id}`)
        .then(res => {
          let timer = setTimeout(() => {
            this.loading = false;
            clearTimeout(timer);
            timer = null;
          }, 150)
          return res;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleReset () {
      if (this.formItem) {
        const formItem = this.formItem
        Object.keys(formItem).map(key => {
          if (this.utils.is_array(formItem[key])) formItem[key] = [];
          if (this.utils.is_string(formItem[key])) formItem[key] = '';
          if (this.utils.is_number(formItem[key])) formItem[key] = 1;
          if (this.utils.is_object(formItem[key])) formItem[key] = {};
          if (this.utils.is_boolean(formItem[key])) formItem[key] = true;
        });
      }
      this.resetCb && this.resetCb();
    }
  }
})
