<template>
  <div style="position:relative">
    <Spin v-if="loading" fix/>
    <h3 style="text-align: center;padding: 10px 0;">
      企业注册
    </h3>
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="LABEL_WIDTH" style="width: 700px;margin: auto">
        <FormItem label="企业名称" prop="name">
          <Input
            v-model="formValidate.name"
            placeholder="请输入企业名称"
          />
        </FormItem>
        <FormItem label="法人" prop="legalPerson">
          <Input v-model="formValidate.legalPerson" placeholder="请输入法人" />
        </FormItem>
        <FormItem label="手机号" prop="legalPhone">
          <Input v-model="formValidate.legalPhone" placeholder="请输入手机号" />
        </FormItem>
        <FormItem label="邮箱" prop="email">
          <Input v-model="formValidate.email" placeholder="请输入邮箱" />
        </FormItem>
        <FormItem label="身份证号" prop="idCode">
          <Input v-model="formValidate.idCode" placeholder="请输入身份证号" />
        </FormItem>
        <FormItem label="身份证正反面" prop="cardPic">
          <AiImgUpload v-model="formValidate.cardPic" @on-upload-done="onUploadCert" :ids="PicArr" :params="uploadData"/>
        </FormItem>
        <FormItem label="证件类型" prop="certType">
          <Select v-model="formValidate.certType">
            <Option v-for="item in cityList" :value="item.value" :key="item.value">
              {{ item.label }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="企业证件" prop="certFile">
          <AiImgUpload v-model="formValidate.certFile" @on-upload-done="onUploadAuth" :ids="FileArr"/>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="handleSubmit('formValidate')">
              提交
            </Button>
            <Button @click="handleReset('formValidate')" style="margin-left: 8px">
              重置
            </Button>
        </FormItem>
      </Form>
  </div>
</template>

<script>
  import editMixin from "@/mixins/editMixin";
  const { mapGetters, mapActions } = window.Vuex;
  export default {
    mixins: [editMixin('Company')],
    data() {
      return {
        model1:'',
        status:'',
        name: '',
        PicArr:[],
        FileArr:[],
        isUploadCert: true,
        isUploadAuth:true,
        cityList:[
          {
            value: '1',
            label: '三证合一'
          },
          {
            value:'2',
            label:'组织机构代码证'
          }
        ],
        formValidate:{
          legalPerson:'',
          legalPhone:'',
          email:'',
          idCode:'',
          certType:'',
          cardPic:[],
          certFile:[]
        },
        ruleValidate: {
          idCode: [
            {
              required: true,
              message: "身份证号格式不正确",
              trigger: "blur",
              min: 18
            },
            {
              message: "身份证号格式最大长度为18位",
              trigger: "blur",
              max: 18
            },
            {
              message: "身份证号格式不正确",
              pattern: new RegExp(
                /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
              )
            }
          ],
          legalPhone: [
            {
              required: true,
              message: "手机号格式不正确,应为11位的数字",
              trigger: "blur",
              pattern: new RegExp(/^[0-9]+$/)
            },
            {
              min: 11,
              trigger: "blur",
              message: "手机号格式不正确,应为11位的数字"
            },
            {
              message: "手机号长度位11位数字",
              trigger: "blur",
              max: 11
            }
          ],
          email: [
            {
              required: true,
              type: "email",
              message: "邮箱格式不正确",
              trigger: "blur"
            }
          ],
          name: [
            {
              required: true,
              message: "企业名称不能为空",
              trigger: "blur"
            }
          ],
          legalPerson: [
            {
              required: true,
              message: "法人不能为空"
            }
          ],
          mail: [
            { type: "email", message: "请输入正确的邮箱格式", trigger: "change",required: true, }
          ],
          certType:[
            {
              required: true,
              message: "证件类型不能为空"
            }
          ],
          cardPic:[
            {
              required: true,
              message: "身份证正反面不能为空"
            }
          ],
          certFile:[
            {
              required: true,
              message: "企业证件不能为空"
            }
          ]
        },
      };
    },
    components: {
    },
    computed: {
      ...mapGetters(["tenantId"]),
      uploadData(){
        return {
          tenant: this.tenantId
        }
      }
    },
    created () {
      this.rmSession();
    },
    async mounted () {
      if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.status = 'edit';
        this.getCompanyInfo(this.id);
      } else {
        this.status = 'add';
      }
    },
    methods: {
      ...mapActions([
        "rmSession",
      ]),
      previewImg (url) {
        if (url.startsWith("http")) {
          return url;
        }
        return `/resource/file/${url}`;
      },
      getFileUrl (ids) {
        return this.$request
          .post('/resource/resource/url', ids)
          .then(res => {
            return res.map(item => this.previewImg(item.url));
          })
          .catch(() => {});
      },
      async getCompanyInfo (id) {
        this.$request.addUnAuthList(`${this.findItemApi || this.restfulApi}/${id}`);
        const info = await this.findItem(id);
        if (info.cardPic) {
          info.cardPic = info.cardPic.split(',');
          this.PicArr = info.cardPic;
          
        }
        if (info.certFile) {
          info.certFile = info.certFile.split(',');
          this.FileArr = info.certFile;
        }
        this.formValidate = info;
        this.formValidate.certType = this.formValidate.certType.val;
      },
      onUploadCert (status) {
        if (status) {
          this.isUploadCert = true;
        } else {
          this.isUploadCert = false;
        }
      },
      onUploadAuth (status) {
        if (status) {
          this.isUploadAuth = true;
        } else {
          this.isUploadAuth = false;
        }
      },
      handleReset (name) {
        this.PicArr = [];
        this.FileArr = [];
        this.$refs[name].resetFields();
      },
      submitSuccess () {
        this.$router.replace('/tip')
      },
      handleSubmit(name) {
        this.$refs[name].validate(valid => {
          if (valid) {
            const formValidate = Object.assign({}, this.formValidate);
            formValidate.cardPic = formValidate.cardPic.map(item => item.id).join(',');
            formValidate.certFile = formValidate.certFile.map(item => item.id).join(',');
            this.saveItem(formValidate);
          }
        });
      },
    },
  };
</script>

<style lang="less">
</style>
